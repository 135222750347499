import React from "react";
import "./Footer.css";
import profile1 from "../../assets/images/about/sb-s1.png";

import { FaFacebookF } from "react-icons/fa";
import { CiInstagram } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import { IoCallOutline } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";
const Footer = () => {
  return (
    <>
      <section>
        <div className="footer">
          <div>
            {" "}
            <h1>Contact Details</h1>
          </div>
          <div className="foot">
            <div className="footer_member">
              <div>
                <h2>For Delegate Registration Enquiries:</h2>
              </div>
              <div className="footer_mem">
                <div className="contact">
                  {/* <img src={profile1} alt="Eera B. Dharmai" /> */}
                  <div className="info">
                    <h3>Eera B. Dharmai</h3>
                    <p>Sr. Business Development Manager</p>
                    <p>
                      <i>
                        <IoCallOutline />
                      </i>
                      +91 992 048 1533
                    </p>
                    <p>
                      <i>
                        <MdMailOutline />
                      </i>
                      eera@globaleducrew.org
                    </p>
                  </div>
                </div>
                <hr />
                <div className="contact">
                  {/* <img src={profile1} alt="Sujitha" /> */}
                  <div className="info">
                    <h3>Sujitha.R</h3>
                    <p>Operations Manager</p>
                    <p>
                      <i>
                        <IoCallOutline />
                      </i>
                      +91 917 676 0863
                    </p>
                    <p>
                      <i>
                        <MdMailOutline />
                      </i>
                      sujitha@globaleducrew.org
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_member">
              <div>
                <h2>For Partnership Enquiries:</h2>
              </div>

              <div className="footer_mem">
                <div className="contact">
                  {/* <img src={profile1} alt="Eera B. Dharmai" /> */}
                  <div className="info">
                    <h3>Dinesh Kumar</h3>
                    <p>Executive Director Sales</p>
                    <p>
                      <i>
                        <IoCallOutline />
                      </i>
                      +91 908 740 5363
                    </p>
                    <p>
                      <i>
                        <MdMailOutline />
                      </i>
                      dineshkumar@globaleducrew.org
                    </p>
                  </div>
                </div>
                <hr />
                <div className="contact">
                  {/* <img src={profile1} alt="Sujitha" /> */}
                  <div className="info">
                    <h3>Shivam Joshi</h3>
                    <p>Associate Director-Partnership</p>
                    <p>
                      <i>
                        <IoCallOutline />
                      </i>
                      +91 917 676 9363
                    </p>
                    <p>
                      <i>
                        <MdMailOutline />
                      </i>
                      shivam@globaleducrew.org
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="copyrights">
          <div>
            <p>
              © 2024,{" "}
              <a href="http://www.gec2024.com">
                <span>WILLIEZ EDU CREW PRIVATE LTD.</span>
              </a>{" "}
              All Rights Reserved.
            </p>
          </div>
       
          {/* <div className="footericon">
            <a href="https://www.facebook.com/share/jwMBjjGy6YvSEwRR/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/global_edu_crew/" target="_blank" rel="noopener noreferrer">
              <CiInstagram />
            </a>
            <a href="https://www.linkedin.com/company/global-edu-crew/mycompany/" target="_blank" rel="noopener noreferrer">
              <CiLinkedin />
            </a>
            <i>
                <FaTwitter />
              </i>
              <i>
                <FaWhatsapp />
              </i>
          </div> */}
           {/* <div className="footericon">
            <a href="https://www.facebook.com/share/jwMBjjGy6YvSEwRR/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/global_edu_crew/" target="_blank" rel="noopener noreferrer">
              <CiInstagram />
            </a>
            <a href="https://www.linkedin.com/company/global-edu-crew/mycompany/" target="_blank" rel="noopener noreferrer">
              <CiLinkedin />
            </a>
          </div> */} 

        <div className="footerterm">
            <a href="Privacy_Policy">Privacy Policy</a>
            <span>|</span>
            <a href="TermsConditions">Terms & Conditions</a>
            <span>|</span>
            <a href="Policy">Pricing, Cancellation, Shipping & Refund Policy</a>
        </div>

          <div className="footericon">
            <a href="https://www.facebook.com/share/jwMBjjGy6YvSEwRR/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/global_edu_crew/" target="_blank" rel="noopener noreferrer">
              <CiInstagram />
            </a>
            <a href="https://www.linkedin.com/company/global-edu-crew/mycompany/" target="_blank" rel="noopener noreferrer">
              <CiLinkedin />
            </a>
          </div>

        </div>
      </section>
    </>
  );
};

export default Footer;
