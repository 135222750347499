export const Navbardata =  {
    menu: [
        {
          title: "HOME",
          path: "/",
          islogin: false,
        },
        {
          title: "ABOUT",
          path: "/#about-section",
          islogin: false,
        },
        {
          title: "SPEAKERS",
          path: "/speaker",
          islogin: false,
        },
        {
          title: "awards",
          path: "/award",
          islogin: false,
        },
        {
          title: "CONTACT",
          path: "/contact",
          islogin: false,
        },
      ],
};
