import React, { useEffect, useState } from 'react';

import { GiPublicSpeaker } from "react-icons/gi";
import { CiCalendarDate } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { BsFillPeopleFill } from "react-icons/bs";
import './About.css'

const CounterComponent = () => {
    const [counters, setCounters] = useState({
        speaker: 0,
        Exhibitors: 0,
        Schools: 0,
        Delegates: 0,
      });
    
      useEffect(() => {
        const targetValues = {
          speaker: 25,
          Exhibitors: 50,
          Schools: 500,
          Delegates: 700,
        };
    
        const updateCounter = (key) => {
          setCounters((prevCounters) => {
            const increment = targetValues[key] / 400;  // Slower increment
            const newValue = Math.min(prevCounters[key] + increment, targetValues[key]);
            return { ...prevCounters, [key]: newValue };
          });
        };
    
        const intervals = Object.keys(targetValues).map((key) =>
          setInterval(() => updateCounter(key), 20)  // Slower interval
        );
    
        return () => intervals.forEach((interval) => clearInterval(interval));
      }, []);

  return (
    <div className="achievement-area">
      <div className="achievement-overlay">
        <div className="container">
          <div className="achievements">
            <div className="single-achievement">
              <div className="achievement-icon">
                {/* <img src={spk} alt="Speaker Icon" /> */}
                {/* <i><GiPublicSpeaker /></i> */}
              </div>
              <h2>
                <span className="number">{Math.ceil(counters.speaker)}</span>+
              </h2>
              <h5>Speakers</h5>
            </div>
            <div className="single-achievement">
              <div className="achievement-icon">
                {/* <img src={calendar} alt="Calendar Icon" /> */}
                 {/* <i><CiCalendarDate /></i> */}
              </div>
              <h2>
                <span className="number">{Math.ceil(counters.Exhibitors)}</span>+
              </h2>
              <h5>Exhibitors</h5>
            </div>
            <div className="single-achievement">
              <div className="achievement-icon">
                {/* <img src={schedule} alt="Schedule Icon" /> */}
                {/* <i><SlCalender /></i> */}
              </div>
              <h2>
                <span className="number">{Math.ceil(counters.Schools)}</span>+
              </h2>
              <h5>Schools</h5>
            </div>
            <div className="single-achievement">
              <div className="achievement-icon">
                {/* <img src={people} alt="People Icon" /> */}
                {/* <i><BsFillPeopleFill /></i> */}
              </div>
              <h2>
                <span className="number">{Math.ceil(counters.Delegates)}</span>+
              </h2>
              <h5>Delegates</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterComponent;
