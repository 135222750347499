import React from 'react';
import Home from '../Component/Home/Home';

const Homepage = () => {
  return (
    <div className='homepage'> 
      <Home />  
    </div>
  );
}

export default Homepage;
