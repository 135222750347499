import React, { useState } from 'react';
import './Speakers.css';
import speaker1 from '../../assets/speakerphotos/Mr Willibrord George.jpg';
import speaker2 from '../../assets/speakerphotos/Ms. Sonal Ravi Andrews.jpeg';
import speaker3 from '../../assets/speakerphotos/Ashoka Latha Beera.jpeg';
import speaker4 from "../../assets/speakerphotos/Ms Asma Zaidi.jpeg";
import speaker5 from "../../assets/speakerphotos/Ms. Deepa Kiran.jpg";
import speaker6 from "../../assets/speakerphotos/Dr Indira.jpeg";
import speaker7 from "../../assets/speakerphotos/Dr. Kayatri Devy.jpeg";
import speaker8 from "../../assets/speakerphotos/Dr. Komala Krishna.jpeg";
import speaker9 from "../../assets/speakerphotos/Kausar Sayeed.jpeg";
import speaker10 from "../../assets/speakerphotos/Gadam Jhon Hemanth Kumar.jpeg";
// import speaker11 from "../../assets/speakerphotos/";
import speaker12 from "../../assets/speakerphotos/Rhea Willibrord.jpeg";
import speaker13 from "../../assets/speakerphotos/Mr Shreyaan Daga.jpeg";
import { RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiTwitterFill, RiWhatsappFill } from "react-icons/ri";
import SpeakerCarts from './SpeakerCards';

const Speakers = () => {
  const speakerdata = [
    {
      title: "Coming Soon...",
      year: 2024,
    },
    {
      picture: speaker1,
      speakername: "Mr. Willibrord George",
      speakerdestination: "Founder",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker2,
      speakername: "Ms. Sonal Ravi Andrews",
      speakerdestination: "Director Founder",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker3,
      speakername: "Ashoka Latha Beera ",
      speakerdestination: "Founder & CEO ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker4,
      speakername: "Ms. Asma Zaidi",
      speakerdestination: "School Leader | Entrepreneur ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker5,
      speakername: "Ms. Deepa Kiran",
      speakerdestination: "Founder of Story Arts Foundation",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker6,
      speakername: "Dr Indira VMD	",
      speakerdestination: "Sr Associate CENTA",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker7,
      speakername: "Dr. Kayatri Devy",
      speakerdestination: "Secretary ECDLA | Founder & Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker8,
      speakername: "Dr. Komala Krishna",
      speakerdestination: "Director - Education SBU",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    }, {
      picture: speaker9,
      speakername: "Kausar Sayeed ",
      speakerdestination: "Founder Director - Learn2Lead Services ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker10,
      speakername: "Gadam Jhon Hemanth Kumar ",
      speakerdestination: "Psychologist & Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    // {
    //   picture: speaker11,
    //   speakername: "Ms. Meera Swaroop  ",
    //   speakerdestination: "Early Childhood Expert, Child Psychologist",
    //   year: 2023,
    //   social: [
    //     { icon: RiFacebookFill, link: "#" },
    //     { icon: RiInstagramFill, link: "#" },
    //     { icon: RiLinkedinFill, link: "#" },
    //     { icon: RiTwitterFill, link: "#" },
    //     { icon: RiWhatsappFill, link: "#" },
    //   ],
    // }
    {
      picture: speaker12,
      speakername: "Rhea Willibrord",
      speakerdestination: "Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker13,
      speakername: "Mr. Shreyaan Daga",
      speakerdestination: "Co-Founder of OLL",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
  ];

  const [selectedYear, setSelectedYear] = useState(2024);

  const filterSpeakers = () => {
    return speakerdata.filter(speaker => speaker.year === selectedYear);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <div>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text"></div>
                <h2 className="page-title">Speakers #GEC2024</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpeakerCarts />
    </div>
  );
}

export default Speakers;
