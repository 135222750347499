import React, { useState } from 'react';
import { SiTicktick } from "react-icons/si";
import { PiMedalFill } from "react-icons/pi";
import "./Award.css"
import { Link } from 'react-router-dom';
const Award = () => {

    const awardsData = [
        {
            id: 1,
            title: "School Awards",
            list: [
                "Best School of the Year",
                "Excellence in STEM Education",
                "Innovative School of the Year",
                "Best Inclusive School",
                "Outstanding Arts Education Program",
                "Best Sports Program",
                "Sustainable School Award",
                "Community Engagement Award",
                "Excellence in Digital Learning ",
                "Best International School",
                "Holistic Development Award ",
                "Best School Infrastructure ",
                "Excellence in Special Education ",
                "Best School Library ",
                "Parental Engagement Award ",
                "Green School of the Year ",
                "Tech Enabled School of the Year ",
                "SDG-Focused Education Excellence Award ",
                "Emerging School of the Year ",
                "Best School in Outstanding International Collaboration"
            ]
        },
        {
            id: 2,
            title: "Individual Awards",
            list: [
                "Education Change Maker of the Year",
                "Community Impact Award ",
                "Educational Equity Advocate",
                "Leader in Educational Policy Reform",
                "Global Education Innovator",
                "Inspirational Education Leader ",
                "Leadership Excellence in Education ",
                "Visionary Principal of the Year",
                "Outstanding Chairperson Award ",
                "Education Policy Innovator ",
                "Mentor of the Year",
                "STEM Educator of the Year "
            ]
        },
        {
            id: 3,
            title: "Lifetime Awards ",
            list: [
                "Lifetime Achievement in Education",
                "Change Maker in Education",
                "Icon of Education Award",
                "Excellence in Education Leadership",
                "Pioneer in Educational Innovation ",
                "Lifetime Contribution to Inclusive Education",
                "Global Impact in Education ",
                "Legacy in Curriculum Development",
                "Champion of Student Success ",
                "Trailblazer in Educational Research "
            ]
        },
        {
            id: 4,
            title: "Products & Services Awards",
            list: [
                "Best Educational Technology Solution ",
                "Outstanding Online Learning Platform",
                "Excellence in Curriculum Development",
                "Top Professional Development Provider",
                "Best Educational Support Services ",
                "Innovative Learning Environment Award ",
                "Excellence in Parental Engagement ",
                "Best Learning Management System (LMS) ",
                "Most Effective Student Assessment Tool ",
                "Outstanding Interactive Learning Resource ",
                "Best Classroom Management Tool ",
                "Top Virtual Reality (VR) Education Solution",
                "Excellence in Language Learning Tools ",
                "Best Mobile Learning Application ",
                "Outstanding EdTech Startup",
                "Innovative Use of Artificial Intelligence in Education",
                "Best Collaboration Tool for Educators",
                "Excellence in STEM Education Products",
                "Outstanding Educational Game",
                "Best Holistic Development Program "
            ]
        },
       
       
    ];

    const [expandedAwards, setExpandedAwards] = useState([]);

    const toggleShowMore = (awardId) => {
        setExpandedAwards(prevState => ({
            ...prevState,
            [awardId]: !prevState[awardId]
        }));
    };


    return (
        <>
            <div className="container-fluid Award_banner_bg">
                <div className="Award_banner_item">
                <div className="nominal-container">
                {/* <button ><a href="/RegisterForm">Register Now</a></button> */}

              <button className="button button-wiggle">
              <Link to="/category" className="links">Nominate Now</Link>
                </button> 
              </div>
                </div>
            </div>

           
            <div className="container-fluid award_main_container">
                {/* <h1 >Awards</h1> */}
                <div className="award_container">
                    {
                        awardsData.map((award) => (
                            <div className="award_item" key={award.id}>
                                <div className="award_head_container" style={{ background: award.id % 2 === 0 ? "#7131ff" : "#00ecbb" }}>
                                    <p className='award_medal'><PiMedalFill /></p>
                                    <h5>{award.title}</h5>
                                </div>
                                <div className="award_list_container">
                                    {
                                        award.list.slice(0, expandedAwards[award.id] ? award.list.length : 6).map((awardList, i) => (
                                            <div className="award_list_item" key={i}>
                                                <div style={{ width: "10%" }}>
                                                    <p className='award_tick'><SiTicktick /></p>
                                                </div>
                                                <div style={{ width: "90%", paddingLeft: "1px" }}>
                                                    <p>{awardList}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    award.list.length > 5 && (
                                        <p className="show_more" onClick={() => toggleShowMore(award.id)}>
                                            {expandedAwards[award.id] ? "Show less" : "Show more"}
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            <div className='award-para'>
              <p>On 5th October 2024, Global EDu Crew #GECAwards2024 will honor individuals and organizations
                 that have made significant strides in the field of education. By integrating innovative strategies and
                 cutting-edge technologies, these pioneers enhance the learning experience for learners across the
                 globe. The Awards will recognize Top Solutions, Influential Educators and Visionary Leaders.</p>

                 <h1>IMPORTANT DISCLAIMER:</h1>

                 <p>Please note that payment for a nomination does not guarantee an award. Only a select few will be
                   chosen as actual awardees. It is our goal to ensure fairness and transparency in our selection
                   process. In the event that a nomination is deemed ineligible for an award by our jury after careful
                   consideration, we will refund the nomination fee.</p>

                   <p>We would also like to clarify that, as a non-profit organization, the nomination charges are
                     necessary to help us sustain this initiative. We incur various costs, including PR, events, human
                     resources, and the gala lunch. Your support through the nomination fee helps us continue to
                     recognize and honor exceptional contributions in the field of education. We appreciate your
                     understanding and participation in this endeavor.</p>
            </div>
            </div>

        </>
    )
}

export default Award;
