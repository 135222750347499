import React from 'react'
import Contact from '../Component/Contact/Contact'

const ContactPage = () => {
  return (
    <div>
      <Contact/>
    </div>
  )
}

export default ContactPage
