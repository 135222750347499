import React from 'react'
import Editspeaker from '../Component/Admin/Editspeaker'

const Editspeakerpge = () => {
  return (
    <div>
        <Editspeaker />
    </div>
  )
}

export default Editspeakerpge