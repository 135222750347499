import React from 'react'
import About from '../Component/About/About'


const Aboutpage = () => {
  return (
    <div>
        <About />
        {/* <AboutCounters />
        <AboutTSpeaker />
        <Sponcerarea />
        <Testimonial /> */}
      
    </div>
  )
}

export default Aboutpage
