import React from 'react'
import Pricing from '../Component/Pge/Pricing'
import Pricingbanner from '../Component/Pge/Pricingbanner'

const Princingpge = () => {
  return (
    <div>
      <Pricingbanner />
      <Pricing />
    </div>
  )
}

export default Princingpge
