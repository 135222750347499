import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addspeaker.css';
import hero from '../../assets/images/hero-figure1.jpg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';

const Editspeaker = () => {
  const { id } = useParams();
  const [speaker, setSpeaker] = useState({
    speaker_name: '',
    description: '',
    speaker_photos: []
  });
  const [selectedFileURLs, setSelectedFileURLs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`http://localhost:8005/api/speakers/${id}`)
      .then(response => {
        setSpeaker(response.data);
        setSelectedFileURLs(response.data.speaker_photos);
      })
      .catch(error => {
        console.error('Error fetching speaker data:', error);
      });
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSpeaker(prevState => ({ ...prevState, [id]: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const fileURLs = files.map(file => URL.createObjectURL(file));
    setSelectedFileURLs(fileURLs);
    setSpeaker(prevState => ({
      ...prevState,
      speaker_photos: files
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('speaker_name', speaker.speaker_name);
    formData.append('description', speaker.description);
    speaker.speaker_photos.forEach((file, index) => {
      formData.append(`speaker_photos[${index}]`, file);
    });
    try {
      await axios.put(`http://localhost:8005/api/speakers/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Speaker updated successfully!');
      setTimeout(() => navigate('/viewspeaker'), 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error('Error updating speaker:', error.response?.data || error);
      toast.error('Failed to update speaker.');
    }
  };

  return (
    <div className='addspeaker'>
      <ToastContainer />
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  {/* <h1>about us</h1> */}
                </div>
                <h2 className="page-title">Edit Speaker</h2>
                <ul className="page-switcher">
                  <li><a href="index.html">Home <i className="bi bi-caret-right"></i></a></li>
                  <li>Edit speaker</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="addspeakers">
        <div className="background-title text-style-one">
          <h2>Edit Speaker</h2>
        </div>
        <div className="contact-section">
          <img src={hero} alt="Hero" />
        </div>
        <div className="contact-form">
          <form id="contact-form" onSubmit={handleSubmit}>
            <div className="contact-form-wrapper">
              <h4 className="contact-form-title">Edit Speaker</h4>
              <div className="primary-input-group">
                <input
                  type="text"
                  id="speaker_name"
                  placeholder="Speaker's name"
                  value={speaker.speaker_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="primary-input-group">
                <textarea
                  id="description"
                  placeholder="Speaker's Description"
                  value={speaker.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="primary-input-group">
                <input
                  type="file"
                  id="speaker_photos"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
              {selectedFileURLs.length > 0 && (
                <div className="image-preview">
                  {selectedFileURLs.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt="Selected"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  ))}
                  <button
                    type="button"
                    className="remove-image-btn"
                    onClick={() => setSelectedFileURLs([])}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div className="submit-btn">
                <button type="submit" className="primary-submit">Submit Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Editspeaker;
