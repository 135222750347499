import React from 'react'
import Addspeaker from '../Component/Admin/Addspeaker'

const Addspeakerpge = () => {
  return (
    <div>
      <Addspeaker />
      
    </div>
  )
}

export default Addspeakerpge
