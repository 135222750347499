import React from 'react'

const Pricingbanner = () => {
  return (
    <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  
                </div>
                <h2 className="page-title">TicketPlan</h2>
                <ul className="page-switcher">
                  <li><a href="index.html">Home <i className="bi bi-caret-right"></i></a></li>
                  <li>Pricing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Pricingbanner
