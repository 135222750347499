import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addspeaker.css';
import abishek from "../../assets/images/speakers/ABHISHEK-BAGCHI.jpg";

const ViewSpeakers = () => {
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:8005/api/speakers')
            .then(response => {
                setSpeakers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the speakers!', error);
            });
    }, []);

    const deleteSpeaker = (id) => {
        axios.delete(`http://localhost:8005/api/speakers/${id}`)
            .then(() => {
                setSpeakers(speakers.filter(speaker => speaker._id !== id));
            })
            .catch(error => {
                console.error('There was an error deleting the speaker!', error);
            });
    };

    return (
        <div>
            <div className="breadcrumb-area">
                <div className="about_bg">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <div className="page-outlined-text">
                                        {/* <h1>about us</h1> */}
                                    </div>
                                    <h2 className="page-title">ViewSpeakers</h2>
                                    <ul className="page-switcher">
                                        <li><a href="index.html">Home <i className="bi bi-caret-right"></i></a></li>
                                        <li>ViewSpeakers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="reply-btns">
                <a href="/addspeaker">ADD</a>
            </div>

            <div className="comment-section">
                <ul className="comments-list">
                    {speakers.map((speaker) => (
                        <li className="single-comment" key={speaker._id}>
                            <div className="commentor-img">
                                <img src={speaker.speaker_photos || abishek} alt="Commentor Image" />
                            </div>
                            <div className="comment-info">
                                <div className="commentor-info">
                                    <div className="commentor-bio">
                                        <h6 className="commentor-name">{speaker.speaker_name}</h6>
                                        <div className="comment-timing">
                                            <span className="comment-date">{speaker.description}</span>
                                            {/* <span className="comment-time">{speaker.time}</span> */}
                                        </div>
                                    </div>
                                    <div className="reply-btn">
                                        <a href={`/editspeaker/${speaker._id}`}>Edit</a>
                                        <button onClick={() => deleteSpeaker(speaker._id)} className="delete-btn">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ViewSpeakers;
