import React from 'react'
import ViewSpeakers from '../Component/Admin/ViewSpeakers'

const Viewspeakerpge = () => {
  return (
    <div>
      <ViewSpeakers />
    </div>
  )
}

export default Viewspeakerpge
