import './App.css';
import Layout from './Layout/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
    
    <Layout/>
    <ToastContainer />
    </>
  );
}

export default App;
 