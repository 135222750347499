import React from 'react'
import './Payment.css'
import { useState } from 'react'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const Payment = () => {
    const [amout, setamout]=useState('')
    const handleSubmit = (e) => {
        e.preventDefault();
        if (amout === "") {
            alert("Please Enter Amout")
        } else {
           var option={
            key: "rzp_test_HJG5Rtuy8Xh2NB",
                key_secret: "",
                amount: amout * 100,
                currency: "INR",
                name: "GEC",
                description: "for testing purpose",
                handler: function (response) {
                    alert(response.razorpay_payment_id);
                },
                prefill: {
                    name: "Ramalakshmi",
                    email: "ramalakshmi.kms@gmail.com",
                    contact: "88888888888"
                },
                notes: {
                    address: ""
                },
                theme: {
                    color: "#3399cc"
                }
           };
           var pay = new window.Razorpay(option);
            pay.open()
        }
    }
  return (
    <div className='RazorPayment'>
        <h2>razor payment</h2>
        <br/><br/>
        <input type='text'placeholder='enteramout' value={amout} onChange={(e) => setamout(e.target.value)} />
        <br/><br/><button onClick={handleSubmit}>submit</button>
    </div>
  )
}

export default Payment