import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        designation: '',
        organization: '',
        email: '',
        contact_number: '',
        website: '',
        address: '',
        interested: [],
        query: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            interested: checked
                ? [...prevState.interested, value]
                : prevState.interested.filter(item => item !== value)
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData); // Debugging log

        emailjs.sendForm('service_mc9grkh', 'template_uzlrndu', e.target, 'Y1WapaYFe1dOIDm47')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send message.');
            });
    };

    return (
        <div className='about'>
            <div className="breadcrumb-area">
                <div className="container-banner">
                    <div className="row align-items-end">
                        <div className="col">
                            <div className="breadcrumb-content">
                                <div className="page-outlined-text"></div>
                                <h2 className="page-title">Contact</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-wrapper">
                <div className="container-contact">
                    <div className="contactContainer">
                        <form className="responsive-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="first-name">Name</label>
                                <div className="name-fields p">
                                    <input
                                        type="text"
                                        id="first-name"
                                        name="first_name"
                                        placeholder='First name'
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        id="last-name"
                                        name="last_name"
                                        placeholder='Last name'
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="designation">Your Designation <span className="required">(Required)</span></label>
                                <input
                                    type="text"
                                    id="designation"
                                    name="designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="organization">Organisation <span className="required">(Required)</span></label>
                                <input
                                    type="text"
                                    id="organization"
                                    name="organization"
                                    value={formData.organization}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Your Email <span className="required">(Required)</span></label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact_number">Your Mobile Number <span className="required">(Required)</span></label>
                                <input
                                    type="text"
                                    id="contact_number"
                                    name="contact_number"
                                    value={formData.contact_number}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="website">Website <span className="required">(Optional)</span></label>
                                <input
                                    type="text"
                                    id="website"
                                    name="website"
                                    placeholder='https://'
                                    value={formData.website}
                                    onChange={handleChange}
                                 />
                            </div>

                            <div className="form-group">
    <label htmlFor="address">Address including city, state & pin code <span className="required">(Required)</span></label>
    <input
        type="text"
        id="address"
        name="address"
        placeholder=''
        value={formData.address}
        onChange={handleChange}
        required
    />
</div>
                          
                            <div className="form-group">
                                <label htmlFor="interested-in">Interested in <span className="required">(Required)</span></label>
                                <div className="checkbox-group">
                                    <label><input type="checkbox" name="interested" value="partner" onChange={handleCheckboxChange} /> Partner</label>
                                    <label><input type="checkbox" name="interested" value="exhibitor" onChange={handleCheckboxChange} /> Exhibitor</label>
                                    <label><input type="checkbox" name="interested" value="speaker" onChange={handleCheckboxChange} /> Speaker</label>
                                    <label><input type="checkbox" name="interested" value="delegate" onChange={handleCheckboxChange} /> Delegate</label>
                                    <label><input type="checkbox" name="interested" value="advertise" onChange={handleCheckboxChange} /> Advertise in GEC Magazine</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="query">Your Query <span className="required">(Required)</span></label>
                                <textarea
                                    id="query"
                                    name="query"
                                    value={formData.query}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="submit-button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;