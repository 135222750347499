import React,{useEffect} from 'react';
import './About.css'
import mini1 from "../../assets/images/About1.jpg";
import mini2 from "../../assets/images/About2.jpg";
import minivideo from "../../assets/images/about/GEC Day 1-105.jpg";

import school from '../../assets/images/abouticons/abouticons/schooldirectors.png'
import Trainer from '../../assets/images/abouticons/abouticons/educationinflu.png'
import admin from '../../assets/images/abouticons/abouticons/eduadmin.png'
import change from '../../assets/images/abouticons/abouticons/changemanagement.png'
import research from '../../assets/images/abouticons/abouticons/researchers.png'
import national from '../../assets/images/abouticons/abouticons/internationalorga.jpg'
import qoute from '../../assets/images/icons/qoute-icon.png'

import { GiPublicSpeaker } from "react-icons/gi";
import { CiCalendarDate } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { GiMicrophone } from "react-icons/gi";
import { SiCrowdsource } from "react-icons/si";
import { MdPeopleAlt } from "react-icons/md";
import { MdCastForEducation } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { MdPublishedWithChanges } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { SiAwsorganizations } from "react-icons/si";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      offset: 150, // Start the animation as soon as the element enters the viewport
      duration: 300, // Adjust the duration for the animation
      easing: "ease-in-quad", // You can use a different easing function if desired
      delay: 100, // Ensure there is no delay
    });

    // Refresh AOS on window load to ensure animations are correctly initialized
    window.addEventListener('load', AOS.refresh);

    return () => {
      window.removeEventListener('load', AOS.refresh);
    };
  }, []);
  return (
    <div className='about'>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  {/* <h1>about us</h1> */}
                </div>
                <h2 className="page-title">About #GEC2024</h2>
                {/* <ul className="page-switcher">
                  <li><a href="/">Home <i className="bi bi-caret-right"></i></a></li>
                  <li>About </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-wrapper ">
        <div className="container">
          <div className="about-company">
            
              <div className="about-company-cont">
                <div className="company-info">
                 
                  {/* <h2>Innovating, Integrating, and Collaborating for the 21st Century"</h2> */}
                  <p>The Global Educators Conclave 2024 aims to bring together K-12 school leaders from Tamil Nadu and beyond, fostering collaboration and sharing innovative teaching methodologies, integrating Technology in Education, designing Future-Ready Curriculum, and exploring Global Educational Policies and Practices. This event serves as a pivotal platform for educators to exchange ideas, share best practices, and collectively address challenges in education, with the overarching goal of enhancing learning outcomes and preparing students effectively for the demands of the 21st century.
</p>
                  {/* <div className="about-icon">
                    <div className="about-icons">
                      <div className="about-infobox">
                        <div className="info-icon">
                          <i><GiPublicSpeaker /></i>
                        </div>
                        <div className="info-content">
                          <h3>100+</h3>
                          <h6>Speaker</h6>
                        </div>
                      </div>
                    </div>
                    <div className="about-icons">
                      <div className="about-infobox">
                        <div className="info-icon">
                          <i><CiCalendarDate /></i>
                        </div>
                        <div className="info-content">
                          <h3>100+</h3>
                          <h6>Event</h6>
                        </div>
                      </div>
                    </div>
                    <div className="about-icons">
                      <div className="about-infobox">
                        <div className="info-icon">
                        <i><SlCalender /></i>
                        </div>
                        <div className="info-content">
                          <h3>100+</h3>
                          <h6>Schedule</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-more-btn">
                    <a href="#" className="primary-btn-fill">Read More</a>
                  </div> */}
                </div>
              </div>
              <div className="about-img col-lg-6">
                <div className="company-mini-gallary">
                  <div className="Gallary-item1 Gallary-item">
                    <img src={mini1} alt="Image15" className="aboutimg"/>
                  </div>
                  <div className="Gallary-item2 Gallary-item">
                    <img src={mini2} alt="Image14" className="aboutimg"/>
                  </div>
                  <div className="Gallary-item3 Gallary-item Gallary-video">
                    <img src={minivideo} alt="Image13" className="aboutimgs"/>
                     
                  </div>
                </div>
              </div>
           </div>
        
        
        
        
           <div className="company-features">
        <div className="about-company-features">
           
                <div className="company-feature-box">
                    <div className="feature-icon">
                      <i><GiPublicSpeaker /></i>
                    </div>
                    <h5>We all time provide intelligent speaker</h5>
                </div>
          
            <div className="company-feature-box">
                <div className="feature-icon">
                  <i><GiMicrophone /></i>
                </div>
                <h5>A good answer will best knowledge</h5>
            </div>
            <div className="company-feature-box">
                <div className="feature-icon">
                 <i><SiCrowdsource /></i> 
                </div>
                <h5>We are always dedicated our guest</h5>
            </div>
            <div className="company-feature-box">
                <div className="feature-icon">
                  <i><GiPublicSpeaker /></i>
                </div>
                <h5>We are provide friendly environment</h5>
            </div>
        </div>
        <div className="feature-info">
        <h2>Who are we?</h2>
            <p>Global EDu Crew stands as a premier platform empowering Educators and Stakeholders in India's Education Sector</p>
            <h3>Our mission </h3>
            
            <p>Our mission is to transform Global Education through dialogue and collaboration,
            forging new paths and nurturing leaders who will shape the future of education in our country</p>
            <p> By uniting Global Education Experts and Stakeholders, we aim to innovate and implement cutting-edge
learning practices, envisioning a brighter future for our nation through education</p>
<p> Join us at Global
Educators Conclave as we endeavour to elevate education to unprecedented levels</p>
            {/* <div className="feature-list">
            <h3>Mission</h3>
            <h2>Sed Malesuada Massa Lacinia, Dictum Dui A, Dignissim Sem.</h2>
            <p> By uniting global education experts and stakeholders, we aim to innovate and implement cutting-edge
learning practices, envisioning a brighter future for our nation through educatio</p>
            </div> */}
        </div>
    </div>
    <div className="about-history">
          <h3 className="float-title">WHO CAN ATTEND #GEC2024?</h3>
          <div className="qoute-icon">
            <img src={qoute} alt="Image4" />
          </div>


          <div className="comment-section">
            <ul className="principal">
              <li className="single-comment" data-aos="zoom-in-up">
                <div className="principal-img">
                  {/* <img src={school} alt="Image6" /> */}
                  <MdPeopleAlt className="aicons large-icon" />

              </div>
              <div className="comment-info">
                {/* <h1>Cultivate</h1> */}
                <p >
                School Directors, Principals, Chairpersons and Decision Makers
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                {/* <img src={Trainer} alt="Image8" /> */}
                <MdCastForEducation className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                {/* <h1>Connect</h1> */}
                <p>
                Trainers, Education Influencers and Education Leaders
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                {/* <img src={admin} alt="Image9" /> */}
                <GrUserAdmin className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                {/* <h1>Communicate</h1> */}
                <p>
                Individuals shaping Policies, Curriculum and Educational Administration
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                {/* <img src={change} alt="Image10" /> */}
                <MdPublishedWithChanges className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                {/* <h1>Collabora</h1> */}
                <p>
                Education Change Makers and Implementers of transformative initiatives in Education.
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                {/* <img src={research} alt="Image11" /> */}
                <GiArchiveResearch className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                {/* <h1>Celebrate</h1> */}
                <p>
                Technology Specialists, Researchers and Entrepreneurs
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                {/* <img src={national} alt="Image11" /> */}
                <SiAwsorganizations className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                {/* <h1>Celebrate</h1> */}
                <p>
                International Organizations/Associations, Multilateral &
                        Bilateral Agencies, Embassies, High Commissions &
                        ...Consulates and NGOs in the education space
                </p>
              </div>
            </li>
          </ul>
        </div>
          {/* <div><h2>WHO CAN ATTEND GEC 2024?</h2></div> */}
          <p className="attentpara">
          If you fit into any of these categories or if you are passionate about
            cultivating Educator Leadership and driving change in Education, we
            invite you to participate in GEC 2024. Join a dynamic community of
            Educators, Influencers, and Change Agents to shape the future of
            education and unlock its immense potential for our Learners and
            Society.
          </p>
        </div>
        </div>
      </div>

    </div>



  );
}

export default About;
