import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./RegisterForm.css";

const Category = () => {
  const [amounts, setAmounts] = useState([3999, 34999, 14999, 34999]);
  const [multipliedAmounts, setMultipliedAmounts] = useState([0, 0, 0, 0]);

  const handleAddClick = (index) => {
    const newMultipliedAmounts = [...multipliedAmounts];
    if (newMultipliedAmounts[index] === 0) {
      newMultipliedAmounts[index] = amounts[index];
    } else {
      newMultipliedAmounts[index] *= 2;
    }
    setMultipliedAmounts(newMultipliedAmounts);
    
    toast.success('Amount added successfully!');
  };

  const handleSubtractClick = (index) => {
    const newMultipliedAmounts = [...multipliedAmounts];
    if (newMultipliedAmounts[index] > 0) {
      newMultipliedAmounts[index] = newMultipliedAmounts[index] / 2;
      if (newMultipliedAmounts[index] < amounts[index]) {
        newMultipliedAmounts[index] = 0;
      }
    }
    setMultipliedAmounts(newMultipliedAmounts);
  };

  const totalAmount = multipliedAmounts.reduce((acc, curr) => acc + curr, 0);

  const handleCheckOutClick = () => {
    alert ("hi")
    if (totalAmount <= 0) {
      toast.error('Please add your amount!');
    }
  };

  return (
    <div className="catercontainer">

      {amounts.map((amount, index) => (
        <div className="catergorycard" key={index}>
          <div className="card-content">
            <h2>
              {index === 0
                ? 'School Leaders Delegate Pass'
                : index === 1
                ? 'Non Educators Delegate Pass'
                : index === 2
                ? 'School Award Nomination'
                : 'Products & Service Providers Award Nominations'}
            </h2>
            <p className="price">₹{amount}</p>
            <p>
              {index === 0
                ? 'Vice Principals, Deans, Head of Academics, Principals, Directors, Chairpersons and Member of the Board of Management Only'
                : index === 1
                ? 'Consultants, Service Providers, Edtech Professionals, etc'
                : 'Please select subcategory while filling up the registration form, also please attach supporting documents in a single PPT/PDF/DOC file'}
            </p>
          </div>
          <div className="button-group">
            <button className="add-button first-button" onClick={() => handleAddClick(index)}>
              +ADD
            </button>
            <button className="add-button second-button" onClick={() => handleSubtractClick(index)}>
              -
            </button>
          </div>
        </div>
      ))}
      <div className="total-container">
        {totalAmount > 0 ? (
          <Link to="/registerForm" state={{ totalAmount }} className="totalamount">
            <button className="total-button" onClick={handleCheckOutClick}>
              Check out: ₹{totalAmount}
            </button>
          </Link>
        ) : (
          <button className="total-button" onClick={handleCheckOutClick} disabled={totalAmount <= 0}>
            Check out: ₹{totalAmount}
          </button>
        )}
      </div>
    </div>
  );
};

export default Category;
