import React, { useState } from 'react';
import './Navbar.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPopup = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://anjumrazi.com/api2/user/login',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        const { token } = response.data;
        toast.success('Logged in Successfully');
        localStorage.setItem('token', token);
        setTimeout(() => {
          window.location.href = '/viewspeaker'; // Redirect to viewspeaker
        }, 1000);
      } else {
        setLoginError(true);
        toast.error('Incorrect Email or Password. Please try again');
      }
    } catch (err) {
      toast.error('Error during login. Please try again');
      console.log(err);
    }
  };

  return (
    <>
      {show && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-body">
              <button onClick={handleClose} className="close">
                &times;
              </button>
              <h4 className="logform">Login</h4>
              <form onSubmit={handleSignIn}>
                <input
                  type="text"
                  name="email"
                  className="username form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  name="password"
                  className="password form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input className="btn login" type="submit" value="Login" />
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPopup;
