import React from 'react'
import Speakers from '../Component/Speakers/Speakers'

const Speakerpge = () => {
  return (
    <div>
      <Speakers />
    </div>
  )
}

export default Speakerpge
