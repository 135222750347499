import React from 'react';
import { useLocation } from 'react-router-dom';
import "./RegisterForm.css";

const RegisterForm = () => {
  const location = useLocation();
  const { totalAmount } = location.state || {}; // Retrieve totalAmount from state

  return (
    <section className="form-section">
      <div className="form-div">
        <div className="form-content">
          <h3>Register Form</h3>
          <form className="form">
            <div className="form-div-inputs">
              <label htmlFor="fullName">Full Name*</label>
              <input
                type="text"
                name="fullName"
                required
              />
            </div>

            <div className="form-div-inputs">
              <label htmlFor="email">Email (Tickets will be sent to this email)*</label>
              <input
                type="email"
                name="email"
                required
              />
            </div>

            <div className="form-div-inputs">
              <label htmlFor="confirmEmail">Confirm Email*</label>
              <input
                type="email"
                name="confirmEmail"
                required
              />
            </div>

            <div className="form-div-inputs">
              <label htmlFor="contactNumber">Contact Number*</label>
              <input
                type="tel"
                name="contactNumber"
                required
              />
            </div>

            <div className="form-div-inputs">
              <label htmlFor="address">Address*</label>
              <input
                type="text"
                name="address"
                required
              />
            </div>

            <div className="form-div-inputs">
              <label htmlFor="amount">Amount</label>
              <input
                type="text"
                name="amount"
                value={`₹${totalAmount || '0'}`} // Display totalAmount
                disabled
                required
              />
            </div>

            <button type="submit" name="submit">Pay Now</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RegisterForm;


// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import QRCode from 'qrcode.react';
// import "./RegisterForm.css";

// const RegisterForm = () => {
//   const location = useLocation();
//   const { totalAmount } = location.state || {}; // Retrieve totalAmount from state
//   const [showQRCode, setShowQRCode] = useState(false);

//   const handlePayment = () => {
//     setShowQRCode(true);
//   };

//   const paymentLink = `https://example.com/dummy-payment?amount=${totalAmount}&orderId=unique-order-id`;

//   return (
//     <section className="form-section">
//       <div className="form-div">
//         <div className="form-content">
//           <h3>Register Form</h3>
//           <form className="form">
//             {/* Form inputs here */}
//             <div className="form-div-inputs">
//               <label htmlFor="amount">Amount</label>
//               <input
//                 type="text"
//                 name="amount"
//                 value={`₹${totalAmount || '0'}`} // Display totalAmount
//                 disabled
//                 required
//               />
//             </div>

//             {!showQRCode ? (
//               <button type="button" onClick={handlePayment}>
//                 Pay Now
//               </button>
//             ) : (
//               <div className="qr-code-container">
//                 <QRCode value={paymentLink} />
//                 <p>Scan this QR code to complete the payment.</p>
//               </div>
//             )}
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default RegisterForm;

// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import QRCode from 'qrcode.react';
// import axios from 'axios';
// import "./RegisterForm.css";

// const RegisterForm = () => {
//     const location = useLocation();
//     const { totalAmount } = location.state || {}; // Retrieve totalAmount from state
//     const [showQRCode, setShowQRCode] = useState(false);
//     const [paymentLink, setPaymentLink] = useState('');

//     const handlePayment = async () => {
//         try {
//             const response = await axios.post('http://localhost:5000/initiate-payment', {
//                 amount: totalAmount,
//                 transactionId: 'unique-order-id' // Generate or use an actual unique order ID
//             });
//             setPaymentLink(response.data.paymentUrl);
//             setShowQRCode(true);
//         } catch (error) {
//             console.error('Payment initiation failed:', error);
//         }
//     };

//     return (
//         <section className="form-section">
//             <div className="form-div">
//                 <div className="form-content">
//                     <h3>Register Form</h3>
//                     <form className="form">
//                         {/* Form inputs here */}
//                         <div className="form-div-inputs">
//                             <label htmlFor="amount">Amount</label>
//                             <input
//                                 type="text"
//                                 name="amount"
//                                 value={`₹${totalAmount || '0'}`} // Display totalAmount
//                                 disabled
//                                 required
//                             />
//                         </div>

//                         {!showQRCode ? (
//                             <button type="button" onClick={handlePayment}>
//                                 Pay Now
//                             </button>
//                         ) : (
//                             <div className="qr-code-container">
//                                 <QRCode value={paymentLink} />
//                                 <p>Scan this QR code to complete the payment.</p>
//                             </div>
//                         )}
//                     </form>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default RegisterForm;


