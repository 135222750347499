import React, { useState } from 'react';
import axios from 'axios';
import './Addspeaker.css';
import hero from '../../assets/images/hero-figure1.jpg';
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css';

const Addspeaker = () => {
  const [formData, setFormData] = useState({
    speaker_name: '',
    description: '',
    speaker_photos: [],
  });

  const [selectedFileURLs, setSelectedFileURLs] = useState([]);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === 'speaker_photos') {
      const filesArray = Array.from(files);
      setFormData({
        ...formData,
        speaker_photos: filesArray,
      });
      const fileURLs = filesArray.map((file) => URL.createObjectURL(file));
      setSelectedFileURLs(fileURLs);
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleRemoveImage = () => {
    setFormData({
      ...formData,
      speaker_photos: [],
    });
    setSelectedFileURLs([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('speaker_name', formData.speaker_name);
      formDataToSend.append('description', formData.description);

      // Append selected files to form data with the desired parameter name and file name
      formData.speaker_photos.forEach((file) => {
        formDataToSend.append('speaker_photos', file, file.name);
      });

      // Make an HTTP POST request using Axios
      const response = await axios.post('http://localhost:8005/api/speakers', formDataToSend);

      if (response.status === 200) {
        // Handle successful form submission
        console.log('Speaker added successfully!');
        // Clear form data
        event.target.reset();
        setFormData({
          speaker_name: '',
          description: '',
          speaker_photos: [],
        });
        setSelectedFileURLs([]);
        // Display success message using Toastify
        toast.success('Speaker added successfully!');
      } else {
        // Handle error response
        console.error('Failed to add speaker:', response.statusText);
        // Display error message using Toastify
        toast.error('Failed to add speaker');
      }
    } catch (error) {
      // Handle network errors or Axios-specific errors
      console.error('An error occurred:', error);
      // Display error message using Toastify
      toast.error('An error occurred');
    }
  };

  return (
    <div className='addspeaker'>
      <ToastContainer /> {/* Add this to render toast notifications */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  {/* <h1>about us</h1> */}
                </div>
                <h2 className="page-title">Addspeaker</h2>
                <ul className="page-switcher">
                  <li><a href="index.html">Home <i className="bi bi-caret-right"></i></a></li>
                  <li>Add speaker</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="addspeakers">
        <div className="background-title text-style-one">
          <h2>Contact Now</h2>
        </div>
        <div className="contact-section">
          <img src={hero} alt="Hero" />
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit} id="contact-form">
            <div className="contact-form-wrapper">
              <h4 className="contact-form-title">Add Speakers</h4>
              <div className="primary-input-group">
                <input
                  type="text"
                  id="speaker_name"
                  placeholder="Speaker's name"
                  value={formData.speaker_name}
                  onChange={handleChange}
                />
              </div>
              <div className="primary-input-group">
                <textarea
                  id="description"
                  placeholder="Speaker's Description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <div className="primary-input-group">
                <input
                  type="file"
                  id="speaker_photos"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
              {selectedFileURLs.length > 0 && (
                <div className="image-preview">
                  {selectedFileURLs.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt="Selected"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  ))}
                  <button type="button" onClick={handleRemoveImage} className="remove-image-btn">Remove Image</button>
                </div>
              )}
              <div className="submit-btn">
                <button type="submit" className="primary-submit">Submit Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Addspeaker;
