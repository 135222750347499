import React from 'react'
import './Pge.css'
import silverl from "../../assets/images/icons/silverl-fa.png"
import gold from "../../assets/images/icons/gold-fa.png"
import diamond from "../../assets/images/icons/diamond-fa.png"


const Pricing = () => {
    return (
        <div>
            <div className="pricing-wrapper">
                <div className="container position-relative pt-110">
                    <div className="pricing-section">
                        <div className="pricing-category">
                            {/* <h2>Corporate</h2> */}
                        </div>
                        <div className="princing-head">
                            <h3>Get Sponsor Ticket</h3>
                        </div>
                        <div className="pricing-tabs">
                            <ul className="tab-list">
                                <li className="tab-item active">
                                    <button>Corporate <span>01</span></button>
                                </li>
                                <li className="tab-item">
                                    <button>Business <span>02</span></button>
                                </li>
                                <li className="tab-item">
                                    <button>Creative <span>03</span></button>
                                </li>
                            </ul>
                        </div>
                        <div className="pricing-cards">
                            <div className="pricing-card">
                                <div className="pricing-card-top">
                                    <h5 className="plan-status">Silver</h5>
                                    <h3 className="plan-price"> <span>$</span> 100</h3>
                                    <div className="plan-icon">
                                        <img src={silverl} alt="image76" /></div>
                                </div>
                                <div className="pricing-features">
                                                <div className="ticket-availability">
                                                    <h5>Available Tickets</h5>
                                                    <h4>270 / <span>500</span></h4>
                                                </div>
                                                <div className="feature-list">
                                                    <ul>
                                                        <li className="tick"><i className="bi bi-check2"></i> Conference Ticket
                                                        </li>
                                                        <li><i className="bi bi-x"></i> Free Certificate</li>
                                                        <li><i className="bi bi-x"></i> Free Lunch & Coffe</li>
                                                    </ul>
                                                </div>
                                                <div className="purchase-btn">
                                                    <a href="#">Buy Ticket</a>
                                                </div>
                                            </div>
                            </div>
                            <div className="pricing-card">
                                <div className="pricing-card-tops">
                                <div className="pricing-card-lavel">
                                                    <span>10%</span> Off
                                                </div>
                                    <h5 className="plan-status">Silver</h5>
                                    <h3 className="plan-price"> <span>$</span> 100</h3>
                                    <div className="plan-icon"><img src={gold}
                                        alt="Image89" /></div>
                                </div>
                                <div className="pricing-features">
                                                <div className="ticket-availability">
                                                    <h5>Available Tickets</h5>
                                                    <h4>270 / <span>500</span></h4>
                                                </div>
                                                <div className="feature-list">
                                                    <ul>
                                                        <li className="tick"><i className="bi bi-check2"></i> Conference Ticket
                                                        </li>
                                                        <li><i className="bi bi-x"></i> Free Certificate</li>
                                                        <li><i className="bi bi-x"></i> Free Lunch & Coffe</li>
                                                    </ul>
                                                </div>
                                                <div className="purchase-btns">
                                                    <a href="#">Buy Ticket</a>
                                                </div>
                                            </div>
                            </div>
                            <div className="pricing-card">
                                <div className="pricing-card-top">
                                    <h5 className="plan-status">Silver</h5>
                                    <h3 className="plan-price"> <span>$</span> 100</h3>
                                    <div className="plan-icon"><img src={diamond}
                                        alt="Image88" /></div>
                                </div>
                                <div className="pricing-features">
                                                <div className="ticket-availability">
                                                    <h5>Available Tickets</h5>
                                                    <h4>270 / <span>500</span></h4>
                                                </div>
                                                <div className="feature-list">
                                                    <ul>
                                                        <li className="tick"><i className="bi bi-check2"></i> Conference Ticket
                                                        </li>
                                                        <li><i className="bi bi-x"></i> Free Certificate</li>
                                                        <li><i className="bi bi-x"></i> Free Lunch & Coffe</li>
                                                    </ul>
                                                </div>
                                                <div className="purchase-btn">
                                                    <a href="#">Buy Ticket</a>
                                                </div>
                                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Pricing
