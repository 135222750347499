import React, { useState } from 'react';
import './Navbar.css';
import logo from "../../assets/images/GEC-Logo.png";
import LoginPopup from './LoginPopup'; // Adjust the path as necessary
import { Navbardata } from '../../Data/Navbar/Navbardata';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePopupShow = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className='Navbar_header'>
        <img src={logo} alt='logo' className='Navbar_logo' />
        <div className={`Navbar_menu ${isOpen ? 'open' : ''}`}>
          {Navbardata.menu.map((data, index) => (
            <ul key={index}>
              {!data.islogin && (
                <li onClick={() => {
                  window.location.replace(data.path);
                }}>
                  {data.title}
                </li>
              )}
            </ul>
          ))}
          <button onClick={handlePopupShow}>Admin</button>
        </div>

        <div className='Navbar_toggle' onClick={handleToggle}>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <div className={`Navbar_overlay ${isOpen ? 'open' : ''}`} onClick={handleToggle}></div>

      <LoginPopup show={showPopup} handleClose={handlePopupClose} /> {/* Pass state and handler as props */}
    </>
  );
};

export default Navbar;
