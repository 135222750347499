import React from 'react'
import Schedule from '../Component/Pge/Schedule'
import Schedulebanner from '../Component/Pge/Schedulebanner'

const Pge = () => {
  return (
    <div>
      <Schedulebanner />
      <Schedule />
    </div>
  )
}

export default Pge
