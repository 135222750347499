import React from 'react'
import Router from '../Router/Router';
import Navbar from '../Component/Navbar/Navbar.jsx'
import Footer from '../Component/Footer/Footer.jsx'

const Layout = () => {
  return (
    <div>
        <Navbar/>
        <Router/>
        <Footer/>

    </div>
  )
}

export default Layout