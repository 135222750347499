import React, { useState } from 'react';
import './Speakers.css';
import speaker1 from '../../assets/speakerphotos/Mr Willibrord George.jpg';
import speaker2 from '../../assets/speakerphotos/Ms. Sonal Ravi Andrews.jpeg';
import speaker3 from '../../assets/speakerphotos/Ashoka Latha Beera.jpeg';
import speaker4 from "../../assets/speakerphotos/Ms Asma Zaidi.jpeg";
import speaker5 from "../../assets/speakerphotos/Ms. Deepa Kiran.jpg";
import speaker6 from "../../assets/speakerphotos/Dr Indira.jpeg";
import speaker7 from "../../assets/speakerphotos/Dr. Kayatri Devy.jpeg";
import speaker8 from "../../assets/speakerphotos/Dr. Komala Krishna.jpeg";
import speaker9 from "../../assets/speakerphotos/Kausar Sayeed.jpeg";
import speaker10 from "../../assets/speakerphotos/Gadam Jhon Hemanth Kumar.jpeg";
// import speaker11 from "../../assets/speakerphotos/";
import speaker12 from "../../assets/speakerphotos/Rhea Willibrord.jpeg";
import speaker13 from "../../assets/speakerphotos/Mr Shreyaan Daga.jpeg";
import { RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiTwitterFill, RiWhatsappFill } from "react-icons/ri";

const SpeakerCarts = () => {
  const speakerdata = [
    {
      title: "Coming Soon...",
      year: 2024,
    },
    {
      picture: speaker1,
      speakername: "Mr. Willibrord George",
      speakerdestination: "Founder",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker2,
      speakername: "Ms. Sonal Ravi Andrews",
      speakerdestination: "Director Founder",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker3,
      speakername: "Ashoka Latha Beera ",
      speakerdestination: "Founder & CEO ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker4,
      speakername: "Ms. Asma Zaidi",
      speakerdestination: "School Leader | Entrepreneur ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker5,
      speakername: "Ms. Deepa Kiran",
      speakerdestination: "Founder of Story Arts Foundation",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker6,
      speakername: "Dr Indira VMD	",
      speakerdestination: "Sr Associate CENTA",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker7,
      speakername: "Dr. Kayatri Devy",
      speakerdestination: "Secretary ECDLA | Founder & Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker8,
      speakername: "Dr. Komala Krishna",
      speakerdestination: "Director - Education SBU",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },{
      picture: speaker9,
      speakername: "Kausar Sayeed ",
      speakerdestination: "Founder Director - Learn2Lead Services ",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker10,
      speakername: "Gadam Jhon Hemanth Kumar ",
      speakerdestination: "Psychologist & Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    // {
    //   picture: speaker11,
    //   speakername: "Ms. Meera Swaroop  ",
    //   speakerdestination: "Early Childhood Expert, Child Psychologist",
    //   year: 2023,
    //   social: [
    //     { icon: RiFacebookFill, link: "#" },
    //     { icon: RiInstagramFill, link: "#" },
    //     { icon: RiLinkedinFill, link: "#" },
    //     { icon: RiTwitterFill, link: "#" },
    //     { icon: RiWhatsappFill, link: "#" },
    //   ],
    // }
    {
      picture: speaker12,
      speakername: "Rhea Willibrord",
      speakerdestination: "Director",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker13,
      speakername: "Mr. Shreyaan Daga",
      speakerdestination: "Co-Founder of OLL",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
  ];
  
  const [selectedYear, setSelectedYear] = useState(2024);

  const filterSpeakers = () => {
    return speakerdata.filter(speaker => speaker.year === selectedYear);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <div>
      <div className="speakers-area">
        <div className="position-relative">
          <div>
            <div className="filter_speaker">
              <button
                className={selectedYear === 2024 ? 'active' : 'inactive'}
                onClick={() => handleYearChange(2024)}
              >
                2024 Speaker's
              </button>
              <button
                className={selectedYear === 2023 ? 'active' : 'inactive'}
                onClick={() => handleYearChange(2023)}
              >
                Past Speaker's
              </button>
            </div>

            <div>
              <ul>
                <div className="speakersachievements">
                  {filterSpeakers().map(val => (
                    <div
                      className="speaker-card"
                      key={val.speakername}
                    >
                      {val.picture ? (
                        <div className="speaker-image">
                          <img src={val.picture} alt={val.speakername} />
                          <ul className="speaker-social-icons">
                            {val.social.map((social, index) => (
                              <li key={index}>
                                <a href={social.link}>
                                  <social.icon className='speakersocial' />
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <div className="speaker_comming">
                          <h2 className="speaker_comming_name">{val.title}</h2>
                        </div>
                      )}
                      {val.picture && (
                        <div className="speaker-info">
                          <h5 className="speaker-name">
                            <a href="speaker-details.html">{val.speakername}</a>
                          </h5>
                          <p>{val.speakerdestination}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpeakerCarts;
