import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from '../Pages/Homepage';
import Aboutpage from '../Pages/Aboutpage';
import Speakerpge from '../Pages/Speakerpge';
import ContactPage from '../Pages/ContactPage';
import Pge from '../Pages/Pge';
import Princingpge from '../Pages/Princingpge';
import Addspeakerpge from '../Pages/Addspeakerpge';
import Viewspeakerpge from '../Pages/Viewspeakerpge';
import Editspeakerpge from '../Pages/Editspeakerpge';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute
import Razor from '../Pages/Razor';
import Privacy_Policy from '../Component/Description/Privacy_Policy';
import TermsConditions from '../Component/Description/TermsConditions';
import Policy from '../Component/Description/Policy';
import Category from '../Component/RegisterForm/Category';
import Award from "../Component/Award/Award"
import RegisterForm from "../Component/RegisterForm/RegisterForm"

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path='/award' element={<Award/>} />
        <Route path="/speaker" element={<Speakerpge />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/schedule" element={<Pge />} />
        <Route path="/Pricing" element={<Princingpge />} />
        <Route path="/payment" element={<Razor />} />
        <Route path="/Privacy_Policy" element={<Privacy_Policy />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="/Policy" element={<Policy/>} />
        <Route path="/registerForm" element={<RegisterForm/>} />
        <Route path="/category" element={<Category/>} />


        {/* Protected Routes */}
        <Route path="/addspeaker" element={<ProtectedRoute element={<Addspeakerpge />} />} />
        <Route path="/viewspeaker" element={<ProtectedRoute element={<Viewspeakerpge />} />} />
        <Route path="/editspeaker/:id" element={<ProtectedRoute element={<Editspeakerpge />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
