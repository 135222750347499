import React from 'react'
import "./FooterDescription.css"
const Policy = () => {
  return (

    <div className='FooterDescriptionall'>

<div className='FooterDescription'>
         <div className='FooterDescription_10'>
            <h1>Pricing , Cancellation , Shipping & Refund Policy</h1>
            <h2>Pricing Policy:</h2>
            <p>1.Pricing Transparency: At GEC, we are committed to providing transparent pricing for all our products and services. Our prices are competitive and reflect the quality of education and services we offer.</p>
            <p>2.Payment Methods: We accept payments through various secure methods, including credit/debit cards, online bank transfers, and other payment gateways. Please note that prices may vary depending on the specific product or service you choose.</p>
            <h1>Cancellation Policy:</h1>
            <p>1.No Cancellation Policy:GEC does not offer cancellation for any of our products or services. Once a product or service is purchased, it is considered final and non-refundable. We do not entertain cancellation requests.</p>
            <h1>Shipping Policy:</h1>
            <p>1.No Shipping Required:GEC is an online platform, and we do not offer physical products that require shipping. All our educational materials, courses, and services are delivered electronically through our website or other online platforms.</p>
            <h1>Refund Policy:</h1>      
            <p>1.No Refund Policy:GEC does not provide refunds for any of our products or services. Once a product or service is purchased, it is considered final and non-refundable. We do not offer refunds under any circumstances.</p>
            <h1>Contact Information:</h1>
            <p>If you have any questions or concerns about our policies or need assistance with any of our products or services, please feel free to contact us through the following means:</p>
            <ul>
              <li>Company Name:GEC</li>
              <li>Address: Unit-2, Ground floor, B-wing Hetali Balkrishna CHSLD, Gogate wadi road, Goregaon East, Mumbai – 400063, Maharashtra, India.</li>
              <li>Email: <a href=''>http://www.gec2024.com</a></li>
              <li>Phone: +91 90049 10028</li>
              <p>Please note that by using our website and purchasing our products or services, you agree to abide by these policies. If you have any specific inquiries or require further clarification regarding our policies, please do not hesitate to contact us.</p>
            </ul>
         </div>

    </div>
      </div>

  )
}

export default Policy