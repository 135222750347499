import React, { useState, useEffect } from "react";
import "./Home.css";
import "../About/About.css";

import mini1 from "../../assets/images/aboutimg3.jpg";
import mini2 from "../../assets/images/aboutimg2.jpg";
import minivideo from "../../assets/images/aboutimg1.jpg";
import icon1 from "../../assets/images/princpal/networking (1).png";
import icon2 from "../../assets/images/princpal/cultivate.png";
import icon3 from "../../assets/images/princpal/collaborate.png";
import icon4 from "../../assets/images/princpal/confetti.png";
import speaker1 from "../../assets/images/about/speaker-1.png";
import speaker2 from "../../assets/images/about/speaker-2.png";
import speaker3 from "../../assets/images/about/speaker-3.png";
import speaker4 from "../../assets/images/about/speaker-8.png"
import speaker5 from "../../assets/images/about/speaker-s2.png"
import speaker6 from "../../assets/images/about/speaker-s1.png"

import { RiPlantFill } from "react-icons/ri";
import { IoLogoWechat } from "react-icons/io5";

import school from "../../assets/images/abouticons/abouticons/schooldirectors.png";
import Trainer from "../../assets/images/abouticons/abouticons/educationinflu.png";
import admin from "../../assets/images/abouticons/abouticons/eduadmin.png";
import change from "../../assets/images/abouticons/abouticons/changemanagement.png";
import research from "../../assets/images/abouticons/abouticons/researchers.png";
import national from "../../assets/images/abouticons/abouticons/internationalorga.jpg";
import qoute from "../../assets/images/icons/qoute-icon.png";
import landingcircle from "../../assets/pngwingfinal.png"
import { Link } from 'react-router-dom';
import { FaUsers } from "react-icons/fa6";
import { BsDiagram3Fill } from "react-icons/bs";

import { BsPinMapFill } from "react-icons/bs";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiLinkedinFill,
  RiTwitterFill,
  RiWhatsappFill,
} from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import CounterComponent from "../About/AboutCounters";
import { RiAwardFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";

import AOS from "aos";
import "aos/dist/aos.css";
import { MdPeopleAlt } from "react-icons/md";
import { MdCastForEducation } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { MdPublishedWithChanges } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { SiAwsorganizations } from "react-icons/si";
import SpeakerCarts from "../Speakers/SpeakerCards";
const Home = () => {
  useEffect(() => {
    AOS.init({
      offset: 150, // Start the animation as soon as the element enters the viewport
      duration: 300, // Adjust the duration for the animation
      easing: "ease-in-quad", // You can use a different easing function if desired
      delay: 100, // Ensure there is no delay
    });

    // Refresh AOS on window load to ensure animations are correctly initialized
    window.addEventListener('load', AOS.refresh);

    return () => {
      window.removeEventListener('load', AOS.refresh);
    };
  }, []);
  const [activeIndex, setActiveIndex] = useState(null);



  const calculateTimeLeft = () => {
    const eventDate = new Date("2024-10-05T00:00:00Z");
    const currentTime = new Date();
    const difference = eventDate - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const speakerdata = [
    {
      picture: speaker1,
      speakername: "Santiago Axel",
      speakerdestination: "Senior Visual Designer",
      year: 2024,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker2,
      speakername: "Scarlett Eleanor",
      speakerdestination: "Marketing",
      year: 2024,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker3,
      speakername: "Jacob Logan",
      speakerdestination: "Business Manager",
      year: 2024,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker4,
      speakername: "jeni",
      speakerdestination: "Business manger",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker5,
      speakername: "Jacob",
      speakerdestination: "Marketing",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
    {
      picture: speaker6,
      speakername: "Agnes",
      speakerdestination: "Senior Visual Designer",
      year: 2023,
      social: [
        { icon: RiFacebookFill, link: "#" },
        { icon: RiInstagramFill, link: "#" },
        { icon: RiLinkedinFill, link: "#" },
        { icon: RiTwitterFill, link: "#" },
        { icon: RiWhatsappFill, link: "#" },
      ],
    },
  ];
  const [selectedYear, setSelectedYear] = useState(2024); // Default to 2024 speakers

  const filterSpeakers = () => {
    return speakerdata.filter(speaker => speaker.year === selectedYear);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <>
      <main>
        <section className="hero">
          <div className="hero_bg">
            <div className="hero-content">
              <div className="hero-icon">
         
                <i><FaCalendarAlt /></i>
                <p>5th October 2024</p>

           
                <i><IoLocation /></i>
                <p>Hilton, Chennai</p>
         
               
              </div>


              <h1 className="animate-text">
                The Largest Gathering of K-12 <br /> School Leaders in
                TamilNadu
              </h1>
              <div className="button-container">
                {/* <button ><a href="/RegisterForm">Register Now</a></button> */}

                <Link to="/category" className="linky">
                  <button className="button button-wiggle">
                    Register Now
                  </button>
      </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <div class="circle-text">
       <span>84 Days More</span>
  </div> */}
      {/* card */}
      <section>
        <div className="Search_card">
          <div className="Search_iteams">
            <div className="items_card">
              <i>
                <BsDiagram3Fill />
              </i>
              <h1>Conference</h1>
            </div>
            <div className="items_card">
              <i>
                <RiAwardFill />
              </i>
              <h1>Awards</h1>
            </div>
            <div className="items_card">
              <i>
                <BsPinMapFill />
              </i>
              <h1>Expo</h1>
            </div>
            <div className="items_card">
              <i>
                <FaUsers />
              </i>
              <h1> Networking</h1>
            </div>
          </div>
        </div>
      </section>
      {/*................................................. About................................................... */}
      <section id="about-section">
        <div className="headingall">
          <h1>ABOUT #GEC2024</h1>
        </div>

        <div className="about-wrapper" data-aos="zoom-in" data-aos-delay="0">
          <div className="about-company">
            <div className="about-company-cont">
              <div className="company-info">
                {/* <h5>About GEC 2024</h5> */}
                {/* <h2>
                  Innovating, Integrating, and Collaborating for the 21st
                  Century"
                </h2> */}
                <p>
                  The Global Educators Conclave 2024 aims to bring together K-12 school leaders from Tamil Nadu and beyond, fostering collaboration and sharing innovative teaching methodologies, integrating Technology in Education, designing Future-Ready Curriculum, and exploring Global Educational Policies and Practices. This event serves as a pivotal platform for educators to exchange ideas, share best practices, and collectively address challenges in education, with the overarching goal of enhancing learning outcomes and preparing students effectively for the demands of the 21st century.
                </p>
                {/* <div className="about-icon">
                  <div className="about-icons">
                    <div className="about-infobox">
                      <div className="info-icon">
                        <i>
                          <GiPublicSpeaker />
                        </i>
                      </div>
                      <div className="info-content">
                        <h3>100+</h3>
                        <h6>Speaker</h6>
                      </div>
                    </div>
                  </div>
                  <div className="about-icons">
                    <div className="about-infobox">
                      <div className="info-icon">
                        <i>
                          <CiCalendarDate />
                        </i>
                      </div>
                      <div className="info-content">
                        <h3>100+</h3>
                        <h6>Event</h6>
                      </div>
                    </div>
                  </div>
                  <div className="about-icons">
                    <div className="about-infobox">
                      <div className="info-icon">
                        <i>
                          <SlCalender />
                        </i>
                      </div>
                      <div className="info-content">
                        <h3>100+</h3>
                        <h6>Schedule</h6>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="about-more-btn">
                  <a href="#" className="primary-btn-fill">
                    Read More
                  </a>
                </div> */}
              </div>
            </div>
            <div className="about-img col-lg-6">
              <div className="company-mini-gallary">
                <div className="Gallary-item1 Gallary-item">
                  <img src={mini1} alt="Image1" className="aboutimg" />
                </div>
                <div className="Gallary-item2 Gallary-item">
                  <img src={mini2} alt="Image2" className="aboutimg" />
                </div>
                <div className="Gallary-item3 Gallary-item Gallary-video">
                  <div>
                    <img src={minivideo} alt="Image3" className="aboutimgs" />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*............................................... Counter.................................................... */}
      <section>
        <div>
          <CounterComponent />
        </div>
      </section>
      {/* ...........................................history..................................................  */}
      <section>
        <div className="about-history">
          <h3 className="float-title">WHO CAN ATTEND #GEC2024?</h3>
          <div className="qoute-icon">
            <img src={qoute} alt="Image4" />
          </div>


          {/* <div className="comment-section">
            <ul className="principal">
              <li className="single-comment" data-aos="zoom-in-up">
                <div className="principal-img">
                  <MdPeopleAlt className="aicons large-icon" />

              </div>
              <div className="comment-info">
                <p >
                School Directors, Principals, Chairpersons and Decision Makers
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                <MdCastForEducation className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                <p>
                Trainers, Education Influencers and Education Leaders
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                <GrUserAdmin className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                <p>
                Individuals shaping Policies, Curriculum and Educational Administration
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                <MdPublishedWithChanges className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                <p>
                Education Change Makers and Implementers of transformative initiatives in Education.
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                <GiArchiveResearch className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                <p>
                Technology Specialists, Researchers and Entrepreneurs
                </p>
              </div>
            </li>
            <li className="single-comment" data-aos="zoom-in-up">
              <div className="principal-img">
                <SiAwsorganizations className="aicons large-icon"/>
              </div>
              <div className="comment-info">
                <p>
                International Organizations/Associations, Multilateral &
                        Bilateral Agencies, Embassies, High Commissions &
                        ...Consulates and NGOs in the education space
                </p>
              </div>
            </li>
          </ul>
        </div> */}
          <div className="attendcard_template">
            <div className="attendcard">
              <div className="image_attendcard">
                <MdPeopleAlt className="aicons large-icon" />
              </div>
              <div>
                <p class="title"> School Directors, Principals, Chairpersons and Decision Makers</p>
              </div>

            </div>
            <div className="attendcard">
              <div className="image_attendcard">
                <MdCastForEducation className="aicons large-icon" />
              </div>
              <p class="title">Trainers, Education Influencers and Education Leaders</p>
            </div>
            <div className="attendcard">
              <div className="image_attendcard">
                <GrUserAdmin className="aicons large-icon" />
              </div>
              <p class="title"> Individuals shaping Policies, Curriculum and Educational Administration</p>
            </div>
            <div className="attendcard">
              <div className="image_attendcard">
                <MdPublishedWithChanges className="aicons large-icon" />
              </div>
              <p class="title">Education Change Makers and Implementers of transformative initiatives in Education.</p>
            </div>
            <div className="attendcard">
              <div className="image_attendcard">
                <GiArchiveResearch className="aicons large-icon" />
              </div>
              <p class="title">Technology Specialists, Researchers and Entrepreneurs</p>
            </div>
            <div className="attendcard">
              <div className="image_attendcard">
                <SiAwsorganizations className="aicons large-icon" />
              </div>
              <p className="title">International Organizations/Associations, Multilateral &
                Bilateral Agencies, Embassies, High Commissions &
                ...Consulates and NGOs in the education space</p>
            </div>
          </div>

          <p className="attentpara">
            If you fit into any of these categories or if you are passionate about
            cultivating Educator Leadership and driving change in Education, we
            invite you to participate in GEC 2024. Join a dynamic community of
            Educators, Influencers, and Change Agents to shape the future of
            education and unlock its immense potential for our Learners and
            Society.
          </p>
        </div>
      </section>

      {/* ..............................speakers .......................................................................*/}

      <SpeakerCarts />
      {/* ...............................cards............................................................................. */}
      <section>
        <div className="headingall2">
          <h1>
            Embodying our<span> C5 principles</span> at the Global Educators
            Conclave
          </h1>
        </div>
        <div className="about-card" data-aos="zoom-in-up">
          <div className="card-wrapper">
            <div className="card">
              <div className="cover card-image">
                <div className="content">
                  <img src={icon2} className="imageiconss" />
                  <h2 className="aboutdes">Cultivate</h2>
                </div>
              </div>
              <div className="cover card-info">
                <div className="content">
                  <p>
                    Foster a culture of continuous growth and learning by
                    encouraging the development of innovative teaching methods.
                    Nurture talent and potential in every Educator, cultivating an
                    environment where education thrives.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="card">
              <div className="cover card-image">
                <div className="content">
                  <img src={icon1} className="imageicons" />
                  <h1 className="aboutdes">Connect</h1>
                </div>
              </div>
              <div className="cover card-info">
                <div className="content">
                  <p>
                    Build meaningful relationships with Educators worldwide,
                    sharing knowledge, experiences, and best practices. Strengthen
                    the bonds within the Global Education Community, connecting to
                    inspire and be inspired.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="card">
              <div className="cover card-image">
                <div className="content">
                  <i><IoLogoWechat /></i>
                  <h1 className="aboutdes">Communicate</h1>
                </div>
              </div>
              <div className="cover card-info">
                <div className="content">
                  <p>
                    Engage in one-on-one communication with Education Service Providers and talented Educators from the same field. Share
                    your vision and listen to others, creating a collaborative
                    learning environment through meaningful dialogues and
                    discussions.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="card">
              <div className="cover card-images">
                <div className="content">
                  <img src={icon3} className="imageicons" />
                  <h1 className="aboutdes">Collaborate</h1>
                </div>
              </div>
              <div className="cover card-info">
                <div className="content">
                  <p>
                    Work together to solve educational challenges, leveraging
                    diverse perspectives and expertise. Co-create innovative
                    solutions and strategies, collaborating to achieve greater
                    educational impact.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="card">
              <div className="cover card-images">
                <div className="content">
                  <img src={icon4} className="imageicons" />
                  <h1 className="aboutdes">Celebrate</h1>
                </div>
              </div>
              <div className="cover card-info">
                <div className="content">
                  <p>
                    Recognize and honour the achievements of Educators,
                    celebrating milestones and successes in Education. Appreciate
                    the dedication and passion of the Teaching Community,
                    celebrating to inspire continued excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>
      <section>
        <div className="Next_Time_counter">
          <div className="Next_Time">

            <div className="countdown">
              <div className="timera">
                <h1>We have just</h1>
              </div>
              <div className="timerb">
                <div className="time">
                  <span className="number">{timeLeft.days}</span>
                  <span className="label">Days</span>
                </div>
                <hr />
                <div className="time">
                  <span className="number">{timeLeft.hours}</span>
                  <span className="label">Hour</span>
                </div>
                <hr />
                <div className="time">
                  <span className="number">{timeLeft.minutes}</span>
                  <span className="label">Minute</span>
                </div>
                <hr />
                <div className="time">
                  <span className="number">{timeLeft.seconds}</span>
                  <span className="label">Second</span>
                </div>
              </div>
              <div className="timerc">
                <h1>left</h1>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* ............................................highlights ..................................................................*/}
      <section>
        <div className="headingall2">
          <h1>KEY HIGHLIGHTS #GEC2024</h1>
        </div>

        <div className="question" data-aos="fade-down">
          <div className="questionimg">
            <img src={landingcircle} />
          </div>

          <div className="questionimgs">
            <ul>
              <li className="keys">
                <h2 className="keys1">Inspiring Keynotes</h2>
                <p className="keys2">Hear from Visionary Speakers who are shaping the future of Education. Our keynote sessions will feature Renowned Educators, Thought Leaders, and Policy Makers.</p>
              </li>
              <li className="keys">
                <h2 className="keys1">Interactive Workshops</h2>
                <p className="keys2">Participate in hands-on workshops designed to provide practical tools and strategies. Topics include Technology Integration, Inclusive Education, and Innovative Teaching Methods.</p>
              </li>
              <li className="keys">
                <h2 className="keys1">Networking Opportunities</h2>
                <p className="keys2">Connect with peers from around the world. Share experiences, build professional relationships, and discover collaborative opportunities.</p>
              </li>
              <li className="keys">
                <h2 className="keys1">Exhibition Hall</h2>
                <p className="keys2">Explore the latest educational products, services, and technologies. Meet with Exhibitors who are driving innovation in Education.
                </p>
              </li>
            </ul>
          </div>

        </div>
      </section>
    </>
  );
};

export default Home;
