import React from 'react';
import './Pge.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation'; // Import Swiper navigation CSS
import { RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiTwitterFill } from "react-icons/ri";
import speakersm1 from "../../assets/images/about/speaker-sm1.png";

const Schedule = () => {
    const events = [
        {
            time: "10.00 AM - 11.30 PM",
            room: "01",
            name: "John Loganin",
            role: "Marketing",
            description: "Nulla nisl tellus hendrerit nec dignissim pellentesqu posu in est Suspendisse bibendum.",
            details: "Cras semper, massa vel aliquam luctus, eros odio tempor turpis, ac placerat metus tortor eget magna. Donec mattis posuere pharetra. Donec vestibulum ornare velit ut sollicitudin ut sollicitudin.",
            topics: ["Color", "Typography", "Alignment", "Development", "Graphic Design", "Web Design"]
        },
        {
            time: "11.30 AM - 12.30 PM",
            room: "02",
            name: "Jane Doe",
            role: "Design",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            details: "Proin vehicula ligula sit amet hendrerit sollicitudin. Vivamus venenatis augue non malesuada volutpat.",
            topics: ["UI Design", "UX Design", "Prototyping", "Wireframes", "Interaction Design", "User Research"]
        },
        // Add more events as needed
        {
            time: "12.30 PM - 01.00 PM",
            room: "03",
            name: "Richard Roe",
            role: "Development",
            description: "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.",
            details: "Quisque velit nisi, pretium ut lacinia in, elementum id enim. Nulla porttitor accumsan tincidunt.",
            topics: ["JavaScript", "React", "Node.js", "Databases", "APIs", "Version Control"]
        },
        {
            time: "01.30 PM - 02.30 PM",
            room: "04",
            name: "Emily Smith",
            role: "Marketing",
            description: "Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.",
            details: "Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.",
            topics: ["SEO", "Content Marketing", "Social Media", "Email Marketing", "Analytics", "Advertising"]
        },
      
    ];

    return (
        <div className="recent-schedule-areas">
            <div className="schedulecontainer">
                <div className="background-title text-style-one">
                 
                </div>
                <div className="schedule_sectionhead">
                    <h3>Recent Event Schedule</h3>
                </div>
                
                <div className="schedulecont">
                    <Swiper
                      
                        spaceBetween={50}
                        slidesPerView={1}
                    
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                        <div className="event-date">
                                        <h3>01 Oct 2021</h3>
                                    </div>
                            {events.slice(0, 2).map((event, index) => (
                                <div className="single-schedule-card" key={index}>
                                    <div className="schedule-single-schedule-left">
                                        <div className="schedule-tops">
                                            <h4>{event.time}</h4>
                                            <h5>Room No - <span>{event.room}</span></h5>
                                        </div>
                                        <div className="schedule-bottoms">
                                            <div className="schedule-speaker-image">
                                                <img src={speakersm1} alt="Speaker" />
                                            </div>
                                            <div className="schedule-speaker-info">
                                                <h4>{event.name}</h4>
                                                <p>{event.role}</p>
                                                <ul className="schedule-speaker-social-links">
                                                    <li><a href="#"><i className="fab fa-facebook-f"><RiFacebookFill  /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"><RiInstagramFill /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"><RiLinkedinFill /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"><RiTwitterFill /></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-schedule-rights">
                                        <h3 className="schedule-titles">
                                            <a href="#">{event.description}</a>
                                        </h3>
                                        <p className="schedule-descriptions">{event.details}</p>
                                        <div className="schedule-topicss">
                                            <h5>Topic</h5>
                                            <div className="schedule-topics-grid">
                                                {event.topics.map((topic, i) => (
                                                    <ul key={i}>
                                                        <li>{topic}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="single-schedule-cards">
                                <div className="single-schedule-left">
                                    <div className="schedule-top">
                                        <h4>01.00 AM - 01.30 PM</h4>
                                        <h5>Room No - <span>05</span></h5>
                                    </div>
                                </div>
                                <div className="single-schedule-right has-break">
                                    <h3 className="break-type">Lunch Time</h3>
                                </div>
                            </div>
                            {events.slice(2).map((event, index) => (
                                <div className="single-schedule-card" key={index + 2}>
                                    <div className="schedule-single-schedule-left">
                                        <div className="schedule-tops">
                                            <h4>{event.time}</h4>
                                            <h5>Room No - <span>{event.room}</span></h5>
                                        </div>
                                        <div className="schedule-bottoms">
                                            <div className="schedule-speaker-image">
                                                <img src={speakersm1} alt="Speaker4" />
                                            </div>
                                            <div className="schedule-speaker-info">
                                                <h4>{event.name}</h4>
                                                <p>{event.role}</p>
                                                <ul className="schedule-speaker-social-links">
                                                <li><a href="#"><i className="fab fa-facebook-f"><RiFacebookFill  /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"><RiInstagramFill /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"><RiLinkedinFill /></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"><RiTwitterFill /></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-schedule-rights">
                                        <h3 className="schedule-titles">
                                            <a href="#">{event.description}</a>
                                        </h3>
                                        <p className="schedule-descriptions">{event.details}</p>
                                        <div className="schedule-topicss">
                                            <h5>Topic</h5>
                                            <div className="schedule-topics-grid">
                                                {event.topics.map((topic, i) => (
                                                    <ul key={i}>
                                                        <li>{topic}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SwiperSlide>
                        {/* Add more SwiperSlide components if needed */}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Schedule;
